var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "suggestion_detail" } }, [
    _c("div", { staticClass: "title" }, [
      _c(
        "h3",
        { staticClass: "title-page" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "info", plain: "", size: "mini" },
              on: { click: _vm.goToIndex }
            },
            [_vm._v("戻る")]
          ),
          _vm._v(" 目安箱詳細")
        ],
        1
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "divider" }),
      _vm._v(" "),
      _c("div", { staticClass: "title-tab" }, [_vm._v("目安箱情報")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "result" }, [
        _vm.suggestionById.category
          ? _c("div", [
              _c("b", [_vm._v("カテゴリー：")]),
              _c("span", { staticStyle: { color: "#333" } }, [
                _vm._v(_vm._s(_vm.suggestionById.category.name))
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.suggestionById.show_identity && _vm.suggestionById.name
          ? _c("p", [
              _c("b", [_vm._v("投稿者：")]),
              _c("span", { staticStyle: { color: "#333" } }, [
                _vm._v(_vm._s(_vm.suggestionById.name))
              ])
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: _vm.device.mobile ? 24 : 20 } }, [
              _c("div", { staticClass: "headerTitleTop" }, [
                _c("div", { staticClass: "headerTitle" }, [
                  _vm._v("タイトル：")
                ]),
                _vm._v(" "),
                _c(
                  "aside",
                  { staticClass: "showTitle", staticStyle: { color: "#333" } },
                  [_vm._v(_vm._s(_vm.suggestionById.title))]
                )
              ])
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: _vm.device.mobile ? 24 : 20 } }, [
              _c("div", { staticClass: "headerContentTop" }, [
                _c("div", { staticClass: "headerContent" }, [_vm._v("内容：")]),
                _vm._v(" "),
                _c("aside", { domProps: { innerHTML: _vm._s(_vm.textarea) } })
              ])
            ])
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _vm.suggestionById.attach_files
      ? _c("div", { staticClass: "file" }, [
          _c("div", { staticClass: "headerTitleTop" }, [
            _c("div", { staticClass: "headerTitle" }, [_vm._v("添付ファイル")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "preview" },
              [
                _vm.images.length !== 0
                  ? _c(
                      "el-row",
                      { attrs: { gutter: 8 } },
                      _vm._l(_vm.images, function(item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c(
                              "el-col",
                              { attrs: { xs: 24, sm: 6, md: 4, lg: 4 } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "container",
                                    staticStyle: {
                                      "margin-right": "18px",
                                      "margin-bottom": "18px"
                                    }
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "file_download",
                                        attrs: {
                                          target: "_blank",
                                          href: item.url,
                                          download: ""
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: { src: item.url, alt: "" }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "centered icon_download" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-download",
                                          on: {
                                            click: function($event) {
                                              return _vm.downloadFile(item)
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.files.length
                  ? _c(
                      "div",
                      _vm._l(_vm.files, function(item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _vm.images.length !== 0
                              ? _c("el-row", { attrs: { gutter: 8 } }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        float: "left",
                                        "margin-bottom": "14px",
                                        "margin-right": "8px"
                                      }
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "file_download",
                                          attrs: {
                                            target: "_blank",
                                            href: item.url,
                                            download: ""
                                          }
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "button",
                                              attrs: { plain: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.downloadFile(item)
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-document"
                                              }),
                                              _c("span", {}, [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("truncate")(
                                                      item.file_name,
                                                      _vm.device.mobile
                                                        ? 24
                                                        : 100,
                                                      "..."
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "icon_download",
                                      staticStyle: { "line-height": "2.8" }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-download file",
                                        on: {
                                          click: function($event) {
                                            return _vm.downloadFile(item)
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("div")
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }